import Store from '@/store'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from "pdfmake/build/vfs_fonts"
pdfMake.vfs = pdfFonts.pdfMake.vfs

const expostQrCode = async (merchantId) => {
  try {
    Store.commit('mutateCommonProperties', {
      loading: true
    })

    var docDefinition = {
        content: [
          { qr: 'BHKM' + merchantId, fit: '300', alignment: 'center' },
        ]
    }

    var win = window.open('', '_blank')
    pdfMake.createPdf(docDefinition).open({}, win)
  } catch (error) {
    if (error) {
      console.log('err => ', error)
    }
  }
}

export default {
    expostQrCode
}
